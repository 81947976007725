import { useCallback, useEffect, useState } from "react";
import { isEqual } from "lodash";

const useViewport = () => {
  const isClient = typeof window === "object";
  const getSize = useCallback(
    () => ({
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }),
    [isClient]
  );

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return null;
    }
    const handleResize = () =>
      setWindowSize((size) => {
        const currentSize = getSize();
        return isEqual(size, currentSize) ? size : currentSize;
      });
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isClient, getSize]); // Empty array ensures that effect is only run on mount and unmount

  const width = windowSize.width;
  let screen = "";

  if (width >= 1600) {
    screen = "max";
  } else if (width >= 1440) {
    screen = "xl";
  } else if (width >= 1024) {
    screen = "lg";
  } else if (width >= 720) {
    screen = "md";
  } else if (width >= 480) {
    screen = "sm";
  } else if (width) {
    screen = "xs";
  }

  const devicePixelRatio = isClient ? window.devicePixelRatio : undefined;

  return { windowSize, screen, devicePixelRatio };
};

export default useViewport;

import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import cx from "classnames";
import useHandleOutsideClick from "utils/hooks/useHandleOutsideClick";
import { StyledNavDropdown } from "components/Layout/Navbar/NavDropdown/styles";
import Link from "next/link";
import { AuthContext } from "contexts/AuthProvider";
import { MenuItem } from "components/Layout/Navbar/config";
import Divider from "@/atoms/Divider";
import styled from "styled-components";

export type NavDropdownMenuItem = Omit<MenuItem, "icon">;

export const DROPDOWN_DIVIDER_ITEM = null;

const StyledDivider = styled(Divider)`
  border-color: #5e5e5e;
  margin: 0.5rem 1.5rem;
  border-style: dashed;
`;

export type NavDropdownProps = {
  className?: string;
  label: ReactNode;
  analyticsLabel?: string;
  items: Array<NavDropdownMenuItem | null>;
  overrideVisibility?: boolean;
};

const Index = ({
  label,
  items,
  analyticsLabel,
  className,
  overrideVisibility,
}: NavDropdownProps) => {
  const [expanded, setExpanded] = useState(false);
  const wrapperNode = useRef(null);
  const buttonNode = useRef<HTMLButtonElement | null>(null);

  const handleToggle = () => setExpanded(!expanded);

  useEffect(() => {
    // remove focus
    !expanded && buttonNode.current?.blur();
  }, [expanded]);

  const handleClickOut = () => {
    setExpanded(false);
  };

  useHandleOutsideClick(wrapperNode, handleClickOut, expanded);
  const { openLogoutModal } = useContext(AuthContext) as any;

  const DropdownItem = ({ item }: { item: NavDropdownProps["items"][0] }) => {
    if (item === DROPDOWN_DIVIDER_ITEM) {
      return <StyledDivider or={false} />;
    } else if (item.label === "Sign Out") {
      return (
        <button
          role="menuitem"
          className="navbar-dropdown-item cursor-pointer w-full text-left"
          onClick={() => {
            setExpanded(false);
            openLogoutModal();
          }}
        >
          {item.label}
        </button>
      );
    } else {
      return (
        <Link href={item.url}>
          <a
            role="menuitem"
            className="navbar-dropdown-item"
            data-analytics={`nav-dropdown-item-${analyticsLabel}`}
            onClick={() => setExpanded(false)}
          >
            {item.label}
          </a>
        </Link>
      );
    }
  };

  return (
    <StyledNavDropdown
      className={cx(
        "navbar-dropdown relative",
        { "hidden md:inline": !overrideVisibility },
        className
      )}
      ref={wrapperNode}
    >
      <button
        type="button"
        className="navbar-dropdown-toggle"
        aria-haspopup="true"
        aria-expanded={expanded}
        aria-label={`Toggle ${label} navigation`}
        onClick={handleToggle}
        ref={buttonNode}
      >
        {label}
      </button>
      <ul
        tabIndex={-1}
        role="menu"
        className={cx("navbar-dropdown-menu", { "is-open": expanded })}
      >
        {items.map((item, idx) => (
          <li key={`${item?.label}-${idx}`} role="none">
            <DropdownItem item={item} />
          </li>
        ))}
      </ul>
    </StyledNavDropdown>
  );
};

export default Index;

import { useCallback, useEffect } from "react";
import PropTypes from "prop-types";

const useHandleOutsideClick = (parentNode, onClickOut, condition) => {
  const escapeListener = useCallback(
    (e) => {
      if (
        e.key === "Escape" ||
        !parentNode.current?.contains(document.activeElement)
      ) {
        onClickOut();
      }
    },
    [parentNode, onClickOut]
  );

  const clickListener = useCallback(
    (e) => {
      if (parentNode.current?.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      onClickOut();
    },
    [onClickOut, parentNode]
  );

  useEffect(() => {
    const addEventListeners = () => {
      document.addEventListener("click", clickListener);
      document.addEventListener("mousedown", clickListener);
      document.addEventListener("keyup", escapeListener);
    };
    const removeEventListeners = () => {
      document.removeEventListener("click", clickListener);
      document.removeEventListener("mousedown", clickListener);
      document.removeEventListener("keyup", escapeListener);
    };
    if (condition !== undefined ? condition : true) {
      addEventListeners();
    } else {
      removeEventListeners();
    }
    return removeEventListeners;
  }, [clickListener, escapeListener, condition]);
};

useHandleOutsideClick.propTypes = {
  onClickOut: PropTypes.func.isRequired,
  parentNode: PropTypes.shape({ current: PropTypes.any }).isRequired,
  condition: PropTypes.bool.isRequired,
};

export default useHandleOutsideClick;

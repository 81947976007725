import styled, { keyframes } from "styled-components";
import tw from "twin.macro";

export const IconContainer = styled.div`
  width: 20px;
`;

export const StyledToggle = styled.button`
  display: inline;
  position: relative;
  ${({ theme }) => theme.media["md"]} {
    display: none;
  }
`;

const fadeInVisible = keyframes`
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
`;

const fadeOutHidden = keyframes`
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
`;

export const StyledMenu = styled.div`
  ${tw`fixed h-full w-full z-40 bg-gray5`}
  top: 0;
  left: 0;
  visibility: hidden;
  max-width: 100%;
  z-index: 99999999999;

  #mobile-menu-toggle {
    position: absolute;
    top: 1.25rem;
    left: 1rem;
  }

  &.animated {
    animation: ${fadeOutHidden} 300ms ease forwards;
    &.expanded {
      animation-name: ${fadeInVisible};
    }
  }

  li,
  .sub {
    ${tw`text-white text-center py-2 border-b`}
    &.heading {
      border: none;
    }
  }

  .sub {
    border-color: rgba(128, 128, 128, 0.1);
  }

  li {
    border-bottom-width: 1px;
    border-color: #67676a;
  }

  li:first-of-type {
    border-top-width: 1px;
  }
`;

import React, { ReactNode, useContext, useEffect, useState } from "react";
import { MOBILE_MENU_LINKS } from "components/Layout/Navbar/config";
import {
  IconContainer,
  StyledToggle,
  StyledMenu,
} from "components/Layout/Navbar/MobileNav/styles";
import cx from "classnames";
import { makeUrl } from "utils";
import { AdminContext } from "contexts/AdminProvider";
import Link from "next/link";
import CheckoutSimpleCollapsible from "components/checkout/CheckoutSimpleCollapsible";
import Icon from "@/atoms/Icon";
import LiteratiLogo from "@/atoms/Icon/literati";
import useViewport from "utils/hooks/useViewport";
import { NavDropdownMenuItem } from "components/Layout/Navbar/NavDropdown";
import { MyAccountDropDown } from "components/Layout/Navbar/MyAccountDropDown";

type ListItemProps = {
  label: ReactNode;
  to?: string;
  className?: string;
  feature?: string;
  isFeatureOn: (name: string) => boolean;
};

const ListItem = ({
  label,
  to,
  className,
  feature,
  isFeatureOn,
}: ListItemProps) => {
  if (feature && !isFeatureOn(feature)) {
    return null;
  }

  return (
    <div className={className}>
      {to ? (
        <Link href={makeUrl(to)}>
          <a role="menuitem" className="unstyled-link">
            {label}
          </a>
        </Link>
      ) : (
        label
      )}
    </div>
  );
};

export const MobileDropdown = ({
  item,
  isFeatureOn,
  isOpen,
  onTriggerClick,
}: any) => {
  const DropdownTrigger = ({ label }: any) => (
    <div className="flex items-center justify-center" onClick={onTriggerClick}>
      <div className={"pr-3"}>{label}</div>
      <IconContainer className={"icon-container"}>
        <Icon
          name={"chevron-down-wide"}
          size={"0.75rem"}
          className={cx({ "transform rotate-180": isOpen })}
        />
      </IconContainer>
    </div>
  );

  return (
    <div className={"mobile-dropdown"}>
      <CheckoutSimpleCollapsible
        unmountOnClosed={false}
        trigger={<DropdownTrigger label={item.label} />}
        open={isOpen}
      >
        <div className={"pt-2 pb-8"}>
          {item.childLinks.map((child: NavDropdownMenuItem) => (
            <ListItem
              to={child.url}
              label={child.label}
              key={`mob-${child.url}`}
              className={"sub"}
              isFeatureOn={isFeatureOn}
            />
          ))}
        </div>
      </CheckoutSimpleCollapsible>
    </div>
  );
};

export type MobileNavProps = {
  isAuthenticated: boolean;
};

const MobileNav = ({ isAuthenticated }: MobileNavProps) => {
  const [expanded, setExpanded] = useState(false);
  const [animated, setAnimated] = useState(false);
  const { authenticated, unauthenticated } = MOBILE_MENU_LINKS;
  const links = isAuthenticated ? authenticated : unauthenticated;
  const { isFeatureOn }: any = useContext(AdminContext);
  const { screen } = useViewport();
  const isMobile = ["xs", "sm"].includes(screen);

  const [activeDropdown, setActiveDropdown] = useState(
    isAuthenticated ? "My Account" : "Clubs"
  );

  const toggleMenu = () => {
    document.body.classList.toggle("no-scroll");
    // prevents flash of animated content
    if (!animated && !expanded) {
      setAnimated(true);
    }
    setExpanded(!expanded);
  };

  useEffect(() => {
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, []);

  // close mobile dropdown when expanding to desktop
  useEffect(() => {
    if (expanded && !isMobile) {
      setExpanded(false);
    }
  }, [expanded, setExpanded, isMobile]);

  const Toggle = () => (
    <StyledToggle
      id="mobile-menu-toggle"
      type="button"
      aria-controls="mobile-main-menu"
      aria-expanded={expanded}
      aria-label="Toggle navigation"
      onClick={toggleMenu}
      className="hamburger hamburger--spring"
    >
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </StyledToggle>
  );

  return (
    <>
      <Toggle />
      <StyledMenu
        id="mobile-main-menu"
        className={cx({ animated, expanded: expanded })}
      >
        <Toggle />
        <div className="flex justify-center py-4">
          <Link href="/">
            <a className="navbar-logo">
              <LiteratiLogo />
            </a>
          </Link>
        </div>
        <ul>
          {links.map((item) => (
            <li key={`mob-${item.label}`}>
              {item.label === "My Account" ? (
                <MyAccountDropDown
                  mobile={true}
                  isOpen={item.label === activeDropdown}
                  onTriggerClick={() => setActiveDropdown(item.label)}
                />
              ) : item.childLinks ? (
                <MobileDropdown
                  item={item}
                  isFeatureOn={isFeatureOn}
                  isOpen={item.label === activeDropdown}
                  onTriggerClick={() => setActiveDropdown(item.label)}
                />
              ) : item.label === "Reviews" ? (
                <a
                  key={"mob-reviews"}
                  className={"unstyled-link"}
                  href={"/kids/reviews/"}
                >
                  Reviews
                </a>
              ) : (
                <ListItem
                  to={item.url}
                  label={item.label}
                  key={`mob-${item.label}`}
                  isFeatureOn={isFeatureOn}
                />
              )}
            </li>
          ))}
        </ul>
      </StyledMenu>
    </>
  );
};

export default MobileNav;

import styled from "styled-components";
import tw from "twin.macro";

const caretSize = "1.4rem";

export const StyledNavDropdown = styled.div`
  ${tw`z-20`}
  .navbar-dropdown-toggle {
    ${tw`py-2 pr-5 relative whitespace-nowrap`}
    &:focus {
      ${tw`text-teal`}
    }

    &::after {
      ${tw`inline-block ml-2 text-inherit border-current whitespace-nowrap`}
      content: "";
      border-width: 0.3rem;
      border-bottom-width: 0;
      border-right-color: transparent;
      border-left-color: transparent;
      vertical-align: 0.255rem;
    }
  }

  .navbar-dropdown-menu {
    ${tw`absolute mt-6 py-2 bg-gray5 text-page-white left-0 invisible opacity-0`}
    transition: all 100ms linear;
    will-change: left, opacity, visibility;
    left: -5px;

    &.is-open {
      ${tw`visible opacity-100 left-0`}
    }

    &::before {
      ${tw`border-solid border-gray5 inline-block absolute z-0`}
      content: "";
      transform: rotate(45deg);
      border-width: ${caretSize};
      width: ${caretSize};
      height: ${caretSize};
      top: calc(${caretSize} / 2 * -1);
      left: calc(${caretSize} / 2);
    }
  }
  .navbar-dropdown-item {
    ${tw`relative block whitespace-nowrap px-6 py-2`};
    ${tw`text-inherit no-underline`};
    ${tw`transition duration-300`};
    &:hover,
    &:active,
    &:focus {
      ${tw`bg-teal-light text-ink-black`}
    }
  }

  &.align-right {
    .navbar-dropdown-menu {
      will-change: right, visibility, opacity;
      left: unset;
      right: 5px;

      &.is-open {
        right: 0;
      }

      &::before {
        left: unset;
        right: calc(${caretSize} / 2);
      }
    }
  }
`;

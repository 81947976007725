import Collapsible from "react-collapsible";
import { useState } from "react";

/**
 * Basic implementation of react-collapsible.
 *
 * @note props are passed through to `<Collapsible>`
 *
 * @param {Object} props
 * @param {ReactNode} props.children
 * @param {boolean} props.open
 * @param {ReactNode} props.trigger
 * @param {boolean} props.unmountOnClosed
 */
const CheckoutSimpleCollapsible = ({
  trigger = null,
  unmountOnClosed,
  ...props
}) => {
  const [unmounted, setUnmounted] = useState(!props.open);
  return (
    <Collapsible
      transitionTime={200}
      trigger={trigger}
      triggerDisabled={true}
      overflowWhenOpen={"visible"}
      contentHiddenWhenClosed={true}
      onClose={() => unmountOnClosed && setUnmounted(true)}
      onOpening={() => unmountOnClosed && setUnmounted(false)}
      {...props}
    >
      {unmountOnClosed && unmounted ? null : props.children}
    </Collapsible>
  );
};

CheckoutSimpleCollapsible.defaultProps = {
  unmountOnClosed: true,
};

export default CheckoutSimpleCollapsible;

import styled, { css } from "styled-components";
import { LinkNext, buttonStyles } from "@literati/public-ui-shared-react";

const linkNextSharedStyles = css`
  border-radius: 4px;
  height: 40px;
  min-height: 40px;
  font-size: 0.875rem;
  font-weight: 500;
`;

export const NavBarLinkNext = styled(LinkNext)<
  Partial<buttonStyles.ButtonCommonFocusStylesWithPseudoElementArgs>
>`
  ${linkNextSharedStyles}
  width: 122px;
  max-width: 122px;
  margin: 0 0 0 1rem;

  &::after,
  &:focus::after {
    border-radius: 4px;
  }
`;

export const NavBarLinkNextDark = styled(LinkNext)<
  Partial<buttonStyles.ButtonCommonFocusStylesWithPseudoElementArgs>
>`
  ${linkNextSharedStyles}
  width: 88px;
  max-width: 88px;
  border: 1px solid ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors["ink-black"]};
  color: ${({ theme }) => theme.colors.white};

  &:focus::after {
    border: 2px solid ${({ theme }) => theme.colors.white};
    border-radius: 4px;
  }

  &:focus,
  &:hover,
  &:focus:hover {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors["ink-black"]};
  }
`;

import { LINKS } from "globalConfig";
import { IconName } from "@/atoms/Icon";
import { IconName as DLSIconName } from "@literati/public-ui-shared-react";

export interface MenuItem {
  label: string;
  icon: IconName | DLSIconName;
  url: string;
}
export type MenuItemList = Array<MenuItem | null>;

export const AFFILIATE_MENU: MenuItemList = [
  {
    label: "Classroom Rewards",
    icon: DLSIconName.SCHOOL_BOOKS,
    url: "/teachers/dashboard/",
  },
];

export const USER_ACCOUNT_MENU: MenuItemList = [
  {
    label: "Boxes",
    icon: "box",
    url: LINKS.boxes,
  },
  {
    label: "Account Details",
    icon: "profile",
    url: LINKS.account,
  },
  {
    label: "Billing History",
    icon: "billing",
    url: LINKS.orders,
  },
];

export const USER_ACCOUNT_ACTION_MENU: MenuItemList = [
  {
    label: "Subscription Settings",
    url: LINKS.subscriptions,
    icon: "box-shipment",
  },
  { label: "Sign Out", url: LINKS.logout, icon: "logout" },
];

export const CLUB_LINKS = [
  { label: "Dreamer (0-1 years)", url: LINKS.dreamer },
  { label: "Seeker (0-2 years)", url: LINKS.seeker },
  { label: "Stargazer (1-3 years)", url: LINKS.stargazer },
  { label: "Discoverer (2-4 years)", url: LINKS.discoverer },
  { label: "Scout (3-5 years)", url: LINKS.scout },
  { label: "Tinkerer (4-6 years)", url: LINKS.tinkerer },
  { label: "Ranger (5-7 years)", url: LINKS.ranger },
  { label: "Voyager (6-8 years)", url: LINKS.voyager },
  { label: "Adventurer (7-9 years)", url: LINKS.adventurer },
  { label: "Navigator (8-10 years)", url: LINKS.navigator },
  { label: "Aviator (9-11 years)", url: LINKS.aviator },
  { label: "Trailblazer (10-12 years)", url: LINKS.trailblazer },
  { label: "Wizard (11-13 years)", url: LINKS.wizard },
  { label: "Legend (12-14 years)", url: LINKS.legend },
];

export const GIFT_LINKS = [
  { label: "Give a Gift", url: LINKS.giveGift },
  { label: "Redeem a Gift", url: LINKS.redeemGift },
];

export const MOBILE_MENU_LINKS = {
  unauthenticated: [
    {
      label: "Clubs",
      childLinks: CLUB_LINKS,
    },
    {
      label: "Gifts",
      childLinks: GIFT_LINKS,
    },
    { label: "Reviews", url: LINKS.reviews },
    { label: "Log In", url: LINKS.login },
  ],
  authenticated: [
    {
      label: "My Account",
    },
    {
      label: "Clubs",
      childLinks: CLUB_LINKS,
    },
    {
      label: "Gifts",
      childLinks: GIFT_LINKS,
    },
    { label: "Reviews", url: LINKS.reviews },
    { label: "Sign Out", url: LINKS.logout },
  ],
};

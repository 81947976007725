import cx from "classnames";
import styled from "styled-components";

const StyledDividerOr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  font-size: 0.75rem;
  color: #737373;
  div {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    width: 50px;
    margin: 0 10px;
  }
`;

const StyledDivider = styled.div`
  width: 144px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-right: auto;
  margin-left: auto;
`;

const Divider = ({ className, or }) => {
  return or ? (
    <StyledDividerOr className={cx("divider", className)}>
      <div />
      or
      <div />
    </StyledDividerOr>
  ) : (
    <StyledDivider className={cx("divider", className)} />
  );
};

Divider.defaultProps = {
  className: "my-16",
};

export default Divider;
